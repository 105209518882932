@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");

.nurse_login {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0;
}
.login-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.nurse_img {
  width: 100%;
  height: 40vh;
  background-color: #92e3a9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  margin-top: -40px;
}

.login {
  margin-top: 20px;
  height: 50px;
  width: 150px;
  border: none;
  border-radius: 10px;
  background-color: #7fd3b9;
  font-size: 20px;
  font-family: "Karla", sans-serif;
  color: #fff;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  max-width: 100%;
}

body {
  min-height: 100vh;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding-bottom: 1rem;
  font-size: 1.5em;
}

input[type="text"],
input[type="password"],
textarea {
  padding-left: 10px;
  padding-right: 10px;
  font-size: 15px;
  height: 2.4rem;
  /* margin-top: 20px; */
  border: 2px solid #595959;
  outline: none;
  width: 260px;
  border-radius: 6px;
  margin-top: 7.5px;
  margin-bottom: 7.5px;
  font-family: "Karla", sans-serif;
}

select {
  font-family: "Nunito", sans-serif;
  font-size: 22px;
  padding: 0.25rem;
  border: none;
  border-bottom: 2px solid #7c91a5;
  outline: none;
  margin-bottom: 20px;
}

label,
button {
  /* margin-top: 2rem; */
}

li {
  list-style-type: none;
}
.offscreen {
  position: absolute;
  left: -9999px;
}

.hide {
  visibility: hidden;
}

.valid {
  color: limegreen;
  margin-left: 0.25rem;
}

.invalid {
  color: red;
  margin-left: 0.25rem;
}

.errmsg {
  background-color: lightpink;
  color: firebrick;
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.line {
  display: inline-block;
}

.flexGrow {
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.persistCheck {
  font-size: 0.75rem;
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.persistCheck label {
  margin: 0;
}

[type="checkbox"] {
  height: 20px;
  width: 20px;
  margin: 0 5px 2px 2px;
}

.logi{
  color: #071d32;
  font-weight: 700;
}

.not_account {
  font-size: 0.9rem;
}

@media screen and (min-width: 600px) {
  .nurse_login {
    flex-direction: row;
    /* align-items: flex-start; */
    height: 100vh;
  }
  .nurse_img {
    width: 50%;
    height: 100vh;
  }
  .nurse_img img {
    width: 100%;
  }
  .login-wrapper {
    width: 50%;
    height: 100vh;
    justify-content: center;
  }
  .logo {
    margin-top: 0px;
  }
}
