@import url("https://fonts.googleapis.com/css2?family=Karla&display=swap");

.scenarios_list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 65px;
}

.card_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container_div {
  display: grid;
  text-align: center;
  grid-row-gap: 50px;
  justify-items: center;
  align-items: center;
  padding-top: 50px;
}

.info {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  background-color: #588ad6;
  width: 300px;
  height: 50px;
  border-radius: 8px;
  margin-top: 40px;
}

.card {
  display: flex;
  flex-direction: column;
  width: 80%;
}

.sch {
  background-color: #f2f2f2;
  padding-top: 14px;
  padding-bottom: 14px;
  padding-left: 30px;
  padding-right: 30px;

  display: flex;
  flex-direction: column;
  gap: 5px;
}
.schead {
  color: #222427;
}
.scpara {
  color: #707070;
}
.s1 {
  /* height: auto; */
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  /* border-radius: 10%; */
}
.gitem {
  /* margin: 0px 10px 0px 10px; */
  padding: 10px 0px 10px 0px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: aqua;
}
.layout {
  text-align: center;
  font-weight: 1000;
  color: #ff5353;
  background-color: #d9d9d9;
}

.card_link {
  text-decoration: none;
  color: #707070;
}
