@import url("https://fonts.googleapis.com/css2?family=Karla&display=swap");

.container1 {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 80px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  border-left: 1px solid;
  border-right: 1px solid;
}

.selbox {
  padding: 10px 20px;
  display: flex;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
}

.selbox:hover  {
  background-color: aliceblue;
}

.Sno {
  font-size: 24px;
  color: rgb(40, 36, 36);
  font-weight: 700;
  font-family: "Karla";
}
.AudLin {
  color: rgb(40, 36, 36);
  font-weight: 700;

  font-size: 16px;
  font-family: "Karla";
}

.QnA {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.Ques {
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
  font-size: 14px;
  color: #000000;
  align-items: flex-start;
  font-family: "Karla";
}
.Qhead {
  padding: 0px 20px;
  color: rgb(40, 36, 36);
  font-weight: 700;
  font-size: 20px;
  font-family: "Karla";
}
.cb {
  width: 20px;
  height: 20px;
}

.submitbtn{
  width: 80%;
  height: 50px;
  border-radius: 10px;
  background-color: #7fd3b9;
}
