@import url("https://fonts.googleapis.com/css2?family=Karla&display=swap");

.nav {
  font-size: 14px;
  font-weight: 700;
  font-family: "Karla";
  background-color: #ffff;
  margin-left: 0%;
  margin-top: 0%;
  margin-right: 0%;
  padding: 15px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
  margin-bottom: 10px;
  width: 100%;
  position: fixed;
  z-index: 10;
}
.fi {
  color: #4b4b4b;
}
.se {
  color: #3684cc;
}
