@import url("https://fonts.googleapis.com/css2?family=Karla&display=swap");

.title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 90px;
}

#submission_title {
  color: #382828;
  font-weight: 600;
  font-size: 1.2rem;
  font-family: "Karla", sans-serif;
}

.submission_container {
width: 100%;
}

.scenarios {
  margin-left: auto;
  margin-right: auto;
  background-color: #6a88f4;
  padding: 30px;
  border-radius: 8px;
  max-width: 600px;
}

.scenarioNo {
  
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: capitalize;
  font-weight: 800;
  font-size: 1.1rem;
  color: #fff;
}

.attempts {
  font-weight: bold;
  text-transform: capitalize;
  font-size: 1em;
}

.submission {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.submissionNo {
  width: 100%;
  padding: 30px;
  background-color: white;
  border-radius: 8px;
  color: #383838;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 200px;
  gap: 20px;
}

.submission-time {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#time {
  color: #707070;
}

.view_more {
  color: #fff;
  background-color: #6c7493;
  padding: 10px;
  border-radius: 30px;
  text-align: center;
  cursor: pointer;
}

.no_submissions {
  color: #fff;
  font-size: 1.1rem;
  text-align: center;
}

