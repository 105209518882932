@import url("https://fonts.googleapis.com/css2?family=Nunito&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Karla&display=swap");

.nurse_register {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0;
}

#username,
#password,
.rform {
  padding: 0 0 0;
}

.nurses_gang,
.nurses_reg {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.regi{
  margin-right: 140px;
}

.nurses_gang img {
  width: 100%;
  background-image: no-repeat;
  background-position: center;
  background-size: contain;
  /* mix-blend-mode: multiply; */
}

.reg-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ui {
  display: flex;
  align-items: center;
  gap: 10px;
}

.verify {
}

.inputContainer {
  position: relative;
  display: inline-block;
}

.instructions {
  font-size: 10px;
  position: absolute;
  /* display: inline-block; */
  border-bottom: 1px dotted black;
  visibility: visible;
  width: 90%;
  background-color: black;
  color: rgb(255, 255, 255);
  text-align: center;
  padding: 0 0;
  border-radius: 6px;
  z-index: 1;
}

.mobile-field {
  display: flex;
  gap: 10px;
  align-items: baseline;
  width: 100%;
}

.signup-btn {
  margin: auto;
}

.sign-up {
  background-color: #389df0;
  outline: none;
  border: none;
  border-radius: 10px;
  color: #fff;
  height: 50px;
  width: 130px;
  font-size: 20px;
  cursor: pointer;
}

.sign-up:hover {
  background-color: #64aeea;
}

#mobile {
  width: 192px;
}

#codes {
  font-size: 15px;
  width: 15%;
  /* margin-right: 5px;
  margin-top: 10px; */
}

.fa-solid,
.fas {
  color: #6d8395;
}

.aa {
  color: #389df0;
  text-align: center;
}

.line {
  margin: auto;
  color: #389df0;
  text-align: center;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  max-width: 100%;
}

body {
  min-height: 100vh;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding-bottom: 1rem;
  font-size: 1.5em;
}

input[type="text"],
input[type="password"],
textarea {
  padding-left: 20px;
  font-size: 22px;
  height: 2.4rem;
  /* margin-top: 20px; */
  border: 2px solid #595959;
  outline: none;
  width: 260px;
  border-radius: 6px;
  font-family: "Karla", sans-serif;
}

select {
  font-family: "Nunito", sans-serif;
  font-size: 22px;
  border: none;
  border-bottom: 2px solid #7c91a5;
  outline: none;
}

label,
button {
  /* margin-top: 2rem; */
}

li {
  list-style-type: none;
}
.offscreen {
  position: absolute;
  left: -9999px;
}

.hide {
  visibility: hidden;
}

.valid {
  color: limegreen;
  margin-left: 0.25rem;
}

.invalid {
  color: red;
  margin-left: 0.25rem;
}

.errmsg {
  background-color: lightpink;
  color: firebrick;
  font-weight: bold;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}

.line {
  display: inline-block;
}

.flexGrow {
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.persistCheck {
  font-size: 0.75rem;
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.persistCheck label {
  margin: 0;
}

[type="checkbox"] {
  height: 20px;
  width: 20px;
  margin: 0 5px 2px 2px;
}

@media screen and (min-width: 600px) {
  .nurse_register {
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* align-items: flex-start; */
    height: 100vh;
  }
  .nurses_gang img {
    width: 60%;
    height: auto;
  }
  .nurses_gang {
    background-color: #85cce2;
  }
  .nurse_img img {
    width: 100%;
  }
  .reg-wrapper {
    width: auto;
    height: auto;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .mobile-field {
    justify-content: center;
  }
  .logo {
    margin-top: 0px;
  }

  Lottie {
    left: 44%;
  }
}
